import enLocale from 'element-ui/lib/locale/lang/en'

export default {
  ...enLocale,
  global: {
    search: 'Search',
    operations: 'Operations',
    new: 'New',
    edit: 'Edit',
    yes: 'Yes',
    no: 'No',
    category: 'Category',
    cancel: 'Cancel',
    copy: 'Copy',
    save: 'Save',
    delete: 'Delete',
    data: 'Data',
    weight: 'Weight',
    total_weight: 'Total weight',
    price_list: 'Price list',
    price_total: 'Total price',
    order: 'Order',
    input_placeholder: 'Please enter',
    input_select_placeholder: 'Select'
  },
  task: {
    title: "Title",
    description: "Description",
    user_id: "Responsible",
    milestone_id: "Milestone",
    due: "Due date",
    add_fav: 'Add to favorites,',
    remove_fav: 'Remove from favorites',
    project_id: "Project",
    MenuItem: 'Menu item',
    new_task_from_selection: 'Add task from selection',
    comments: '{count} comments',
    'grouped-by-user': 'Grouped by user',
    overdue: 'Only over due',
    due_today: 'Due today',
    checklist: {
      title: 'Checklist',
      'title-placeholder': 'New element',
      tooltip: '{value}/{of} Tasks',
      add: 'Add element'
    },
    new: {
      title: 'New task'
    },
    history: {
      title: 'History'
    },
    favorites: 'Priority',
    all_tasks: 'Tasks',
    card_view: "Cardview",
    no_milestones: 'No Milestones',
    subtasks: 'subtasks',
    'copy-from-project': 'Copy from project',
    mark_as_done: 'Task is done',
    preview: 'Preview',
    recurring_task: 'Recurring task',
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly',
    yearly: 'yearly',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    every: 'every',
    of_month: 'of the month',
    always_at: 'Always at',
    start_date: 'Interval start',
    end_date: 'Interval end'
  },
  log: {
    type: {
      project: 'Project',
      project_order: 'Order',
      position: 'Position',
      material: 'Material',
      material_additional: 'Additional list',
      task: 'Task',
      subtask: 'subtask',
      milestone: 'Milestone',
      project_person: 'Person',
      project_address: 'Location',
      ride: 'Ride',
      option: 'Option',
      comment: 'Comment',
      person_address: 'Address',
      notification: 'Notification'
    },
    operation: {
      C: 'added',
      U: 'modified',
      D: 'deleted'
    },
    user: 'by {name}'
  },
  news: {
    title: 'News',
  },
  material_additional: {
    title: "Additional article",
    p_address: "Location",
    ride: "Ride",
    quantity: "Quantity",
    key: "ID",
    pt_number: "PT Number",
    unit: "Unit",
    width: "Width",
    height: "Height",
    category: "Category",
    bhb_position: "IM Position",
  },
  data_input: {
    save_close: 'Save & Close',
    save_new: 'Save & New',
    save_next: 'Save & Continue',
    order_label: 'Select Order',
    creators_label: 'Creators',
    all_orders_label: 'All orders',
    all_users_label: 'All users',
    all_creators_label: 'All creators',
    all_milestones_label: 'All milestones',
    input_select_order_placeholder: 'Select order',
    address_label: 'Location',
    input_select_address_placeholder: 'Select location',
    ride_label: 'Ride',
    input_select_ride_placeholder: 'Select ride',
    priority_label: 'Set priority',
    priority: 'Priority',
    reason_placeholder: 'Reason',
    html_editor_placeholder: 'Project description',
    quality_control: 'Quality control',
    divider_sheet: 'Export divider sheet',
    informations: 'Information',
    bhb_position: "IM Position",
    category: "Category",
    unit: "Unit",
    width: "Width",
    height: "Height",
    pt_number: "PT Number",
    quantity: "Quantity",
    construction: 'Construction',
    assembly: 'Assembly',
    material_manager: {
      label: 'Material manager',
      materials_empty: 'No materials',
      constructions_empty: 'No constructions',
      add: 'Add material',
      add_construction: 'Add construction',
      perimeter_placeholder: 'Perimeter (m)',
      area_size_placeholder: 'Area (qm)',
      purchase: 'Purchase',
      purchase_requirement: 'Purchase requirement',
      unit: 'Unit',
      piece: 'Piece',
      fixed: 'Blanket',
      qm: 'qm',
      quantity_label: 'Quantity',
      width_label: 'Width (mm)',
      height_label: 'Height (mm)',
      unit_price_label: 'Price per unit',
      price_percent_label: 'Fee in %',
      qm_price_label: 'Price per qm',
      m_price_label: 'Price per m',
      total_price_label: 'Total price',
      total_price_placeholder: 'Total price M1',
      bemerkung_druck: 'Comment printing',
      bemerkung_dv: 'Comment prepress',
      bemerkung_konfektion: 'Comment confection',
      bemerkung_logistik: 'Comment logistics',
      print_file_path: 'Path to print file',
    },
    motive: 'Motive',
    area_label: 'Area',
    input_select_area_placeholder: 'Select area',
    position_label: 'Position IM',
    input_select_position_im_placeholder: 'Select position IM',
    input_select_assembly_placeholder: 'Select assembly IM',
    add_area_placeholder: 'Add area',
    add_position_placeholder: 'Add position',
    add_picking_placeholder: 'Add picking location',
    set_global_picking_place: 'Set global picking location',
    no_global_place: 'No global location',
    designs: 'Designs',
    miscellaneous: 'Miscellaneous',
    additional_information_checklist: 'Additional information for checklist',
    additional_information_checklist_placeholder: 'Additional information for checklist',
    note_im: 'Note IM',
    note_im_placeholder: 'Note IM',
    bemerkung_techniker: 'Comment technician',
    bemerkung_techniker_placeholder: 'Comment technician',
    image_editor_placeholder: 'Upload',
    input_select_order_placeholder: 'Select Order'
  },
  insurance: {
    new: 'New insurance',
    edit: 'Edit insurance',
    ride_label: 'Ride',
    input_select_ride_placeholder: 'Select ride',
    equipment_included: 'Equipment included',
    piece_module: 'Piece/Modules',
    insurance_topic_label: 'Items to be insured:',
    transport: 'Transport',
    reason: 'Reason',
    license_plate: 'License plate',
    stay_on_site: 'Stay on site',
    by_apa: 'by APA',
    by_customer: 'by customer',
    value_of_goods: 'Value of goods',
    value_currency: 'Euro',
    transport_weight: 'Transport weight'
  },
  message: {
    show_nicht_ok: 'Only "Not Ok"',
    show_has_debriefing: 'Has Debriefing',
    show_dv_erstellt: "Prepress created",
    show_dv_nicht_erstellt: "Prepress not created",
    show_kommisioniert: "Show picked",
    'dv-erstellt': "Prepress created",
    motive: "Motive",
    show_more: 'Show more',
    economic_release_and_state_release: 'Economic/Layout',
    grouping: 'Group By Area',
    production_costs_total: 'Production Costs',
    price_offer: 'Total Project Costs',
    show_rejected: 'Show rejected',
    export_options: 'Export Options',
    rel_business: 'Financial approval',
    'reset-layout': 'Reset layout',
    'file-size': 'Maximum filesize reached. Please choose a smaller image.',
    visibility: 'Visibility',
    internal: 'Internal',
    add: 'Add',
    titleCoverPage: 'Cover page',
    addCoverPage: 'Edit cover page',
    allProjectAddresses: 'All locations',
    upload_files: 'Upload files...',
    amount: 'Quantity',
    functions: 'Functions',
    newLocation: 'New location',
    color: 'Color',
    delivery: 'Delivery',
    pickup: 'Pick up',
    'copying-in-progress': 'Copying in progress',
    editLocation: 'Edit location',
    create_task: 'Create task',
    archiveProject: 'Archive project',
    unarchiveProject: 'Activate project',
    createSubproject: 'Create subproject',
    createOrder: 'Create order',
    updateOrder: 'Update order',
    updateOrderOk: 'Order successfully updated.',
    quote: 'Quote',
    createQuote: 'Create quote',
    updateQuote: 'Update quote',
    updateQuoteOk: 'Quote successfully updated.',
    costAccount: 'Cost account',
    articles: 'Item',
    artkat: 'Item category',
    all_projects:'(All projects)',
    assembly: 'Mounting',
    unitPrice: 'Price per unit',
    assemblyPlanning: 'Assembly',
    bhb: 'Implementation Manual',
    bhb_short: 'IM',
    cancel: 'Cancel',
    chat: 'Chat',
    close: 'Close',
    saveAndInvite: 'Save & Invite',
    comments: 'Comments',
    page_size_all: 'All',
    ordered: 'Ordered',
    komm_orte: 'Picking place',
    orderNr: 'Order nr',
    confirm_ablehnen: 'Do you want to reject this position?',
    confirm_freigabe: 'Do you want to approve this position?',
    confirm_wirtschaftliche_freigabe_on: 'Do you want to give this position financial approval?',
    confirm_wirtschaftliche_freigabe_off: 'Do you want to withdraw financial approval from this position?',
    contactPersonCustomer: 'Contact person customer',
    comment_delete_comfirm: 'Do you really want to delete this comment?',
    dashboard: 'Dashboard',
    dataInput: 'Data Input',
    date: 'Date',
    delete: 'Delete',
    docs: 'Documents',
    accoEdit: 'Edit accomodation',
    edit: 'Edit',
    employee: 'Employees',
    export: 'Export',
    namePlus: 'Name *',
    EditTool: 'Edit tool',
    EditTool: 'Choose tool',
    costCenter: 'Cost center',
    approval: 'Approval?',
    NewTool: 'New tool',
    'save_new_ride': 'Save & New Destination',
    teamMemberPlus: 'Team member *',
    teamMemberSelect: 'Select team member',
    locationPlus: 'Location',
    locationSelect: 'Select location',
    responsibilityPlus: 'Responsibility *',
    responsibilitySelect: 'Select Responsibility',
    teamEdit: 'Edit team member',
    teamNew: 'New team member',
    firstUsageTool: '1. Usage from *',
    firstUsageUntil: 'Until *',
    ride: 'Ride',
    secondUsageTool: '2. Usage from',
    secondUsageUntil: 'Until',
    notificationContactDelete: 'Do you want to delete this contact person?',
    notificationContactDeleted: 'Contact person deleted',
    notificationToolDelete: 'Do you want to delete this tool?',
    notificationToolDeleted: 'Delete tool',
    loadingDate: 'Loading date',
    'reset-layout': 'Reset layout',
    tools: 'Tools',
    editRide: 'Edit ride',
    newRide: 'New ride',
    chooseVehicleFleet: 'Vehicle from fleet',
    chooseVehicle: 'Select vehicle',
    rentalCar: 'Rental car',
    driver: 'Driver',
    loadingPlus: 'Loading',
    'change-status': 'Change state',
    departurePlus: 'Departure',
    unloadingOnSite: 'Unloading on site',
    startOfAssemby: 'Start of assembly',
    loadingOnSite: 'Loading on site',
    unLoadingOnSite: 'Unloading at APA',
    StreetPlus: 'Street *',
    Street: 'Street',
    NumberPlus: 'Number *',
    Number: 'Number',
    ZIPPlus: 'ZIP *',
    responsible: 'Responsibility',
    notificationTeamDelete: 'Do you want to delete this team member?',
    notificationTeamDeleted: 'Delete team member.',
    ZIP: 'ZIP',
    PlacePlus: 'Place *',
    Place: 'Place',
    DateFromPlus: 'Event date from *',
    ChooseDate: 'Select date',
    ChooseDatePicker: 'Select date',
    DateUntilPlus: 'until *',
    DeadlinePrint: 'Deadline print data *',
    externals: 'Contact person customer',
    goToProject: 'Open project',
    hotels: 'Hotels',
    rides: 'Rides',
    showArchived: 'Show archived projects',
    ladeTermin: 'Loading date',
    licensePlate: 'License plate',
    vehiclesInformation: 'Vehicle information',
    pt_matching_vehicles: 'Vehicle in PT',
    infoPool: 'Information pool',
    add_comment: 'Add comment',
    informations: 'Information',
    internal_comment: 'Internal comment',
    internalNotice: 'Internal remark',
    loadingList: 'Loading list',
    loadTemplate: 'Load template',
    montage: 'Assembly',
    more: 'More',
    name: 'Name',
    new: 'New',
    inviteUser: 'Invite user',
    newUserBrackets: '(New user)',
    nameComplete: 'Name',
    contactPersonSelect: 'Select contact person',
    newExternal: 'New contact person',
    editExternal: 'Edit contact person',
    get_report: 'Receives customer report',
    newProject: 'New project',
    no: 'No',
    noData: 'No data',
    newsfeed: 'Newsfeed',
    mark_as_loaded: 'Mark as loaded',
    notice: 'Comment',
    offer: 'Offer',
    ok: 'Ok',
    not_ok: 'Not Ok',
    open: 'Open',
    orderList: 'Order list',
    pickingList: 'Picking list',
    pricelist: 'Price list',
    project: 'Project',
    eventDate: 'Event date',
    projectFacts: 'Project Facts',
    projectId: 'Project-ID',
    projectInformations: 'Project information',
    projectname: 'Project name',
    remark: 'Information',
    reply: 'Reply',
    reStorage: 'Re-storage',
    reStorageList: 'Re-storage',
    rides: 'Rides',
    save: 'Save',
    apply: 'Apply',
    saveProjectAsTemplate: 'Save as template',
    saveProjectNotTemplate: 'No more template',
    saveTemplate: 'As a template',
    settings: 'Settings',
    syncArticle: 'Import items from Dynamics',
    tasks: 'Tasks',
    team: 'Team',
    transportInsurance: 'Transport insurance',
    user: 'User',
    thereFrom: 'Present from *',
    thereUntil: 'Until *',
    vehicle: 'Vehicle',
    vehicles: 'Vehicles',
    warning: 'Attention',
    taskEdit: 'Edit task',
    taskNew: 'New task',
    accoEdit: 'Edit accommodation',
    accoSelect: 'Select accommodation',
    accoNew: 'Add accommodation',
    wirtschaftliche_freigabe_success: 'Financial approval granted.',
    wirtschaftliche_freigabe_removed: 'Financial approval removed.',
    workingAids: 'Tools',
    additionalWorkingAids: 'Additional list',
    yes: 'Yes',
    login_failed: 'Login failed',
    no_privacy: 'Please accept our privacy statement',
    privacy_message: 'I read and agree to the <a href="https://www.apa.de/datenschutz/" target="_blank">privacy rules</a>.',
    terms_message: 'I read and agree to the <a href="https://www.apa.de/agb/" target="_blank">terms</a>.',
    saved: 'Saved',
    from: 'From:',
    until: 'Until:',
    user_saved: 'User successfully saved.',
    roles_rights: 'Roles and rights',
    address: 'Address',
    roles: 'Roles',
    user_roles: 'User Roles',
    password: 'Password',
    password_lost: 'Forgot password?',
    send_forgot: 'Send new password',
    password_change: 'Change passwort',
    old_password: 'My Password',
    email: 'Email',
    position: 'Position',
    user_information: 'User information',
    mail_permission: 'Mail permissions',
    mail: {
      daily_report: 'Täglicher Report',
    },
    avatar: 'Avatar',
    copy: 'Copy',
    language: 'Language',
    lang_de: 'German',
    lang_en: 'English',
    choose_image: 'Choose image...',
    delete_avatar: 'Delete avatar',
    confirm_delete_avatar: 'Do you want to delete this Avatar?',
    einsatzort: 'Location',
    chooseEinsatzort: 'Choose location',
    correction: 'Correction',
    titleBHBComments: 'Information',
    profile: 'Profile',
    projects: 'Projects',
    search: 'Search',
    logout: 'Logout',
    login: 'Login',
    admin: 'Administration',
    user: 'User',
    overview: 'Overview',
    NewVehicles: 'New Vehicle',
    MenuItem: 'Menu item',
    actionPL: 'Action PM',
    noActionPL: 'No action PM',
    inProduction: 'In production',
    stateOpen: 'Open',
    invoicing: 'Invoicing',
    notificationProjectIsSaved: 'Project successfully saved.',
    notificationProjectCannotSaved: 'Project could not be saved.',
    notificationProjectIsArchived: 'Project successfully archived.',
    notificationProjectIsTemplate: 'This project is now a template.',
    notificationProjectIsNoTemplate: 'This project is no longer a template.',
    notificationRideDelete: 'Do you really want to delete this ride?',
    notificationRideDelete1: 'Delete ride',
    list: 'List',
    masse: 'Measurements',
    article: 'Item',
    VehicleFleet: 'Vehicle Fleet',
    dimensions: 'Measure',
    rental_construction: 'Rental construction',
    material: 'Material',
    deleteAdditionalArticle: 'Delete additional article.',
    deleteAdditionalArticleQuestion: 'Do you really want to delete this additional article?',
    additionalInformation: 'Additional information',
    materialConstruction: 'Material / Construction',
    fire_protection_class: 'Certification',
    comNote: 'Picked / Note',
    loadNote: 'Loaded / Note',
    weight: 'Weight',
    rental_price_per_unit: 'Rental price per unit',
    price_per_unit: 'Price per unit',
    total_rental_price: 'Total rental price',
    price_total: 'Price Total',
    price: 'Price',
    korrektur_success: 'Changed to correction',
    korrektur_done: 'Correction done',
    release: 'Layout approval',
    allProjects: 'All projects',
    released: 'Approved',
    emptyProject: 'Empty project',
    reject: 'Reject',
    economic_release: 'Financial approval',
    no_economic_release: 'No financial approval',
    area: 'Area',
    milestone: 'Milestone',
    add_sub_task: 'New subtask',
    size: 'Size',
    actions: 'Actions',
    documents: 'Documents',
    place_of_action: 'Location',
    present: 'Present',
    hotel: 'Hotel',
    accommodations: 'Accommodations',
    notifications: 'Notifications',
    milestones: 'Milestones',
    state: 'Status',
    bhbState: {
      'ALLE': 'All status',
      'LEER': 'Empty',
      'STATUS': 'Status',
      'OFFEN': 'Open',
      'KORREKTUR': 'Correction',
      'HANDLUNG_PL': 'Action PM',
      'FREIGEGEBEN': 'Approved',
      'IN_PRODUKTION': 'In Production',
      'ABGELEHNT': 'Rejected',
      'BEMERKUNG': 'Comment',
      'GELADEN': 'Loaded',
      'ZURUECK': 'Return',
      'KOMMISSIONIERT': 'Picked'
    },
    breadcrumb: {
      'new-project': 'New project',
      'my-dashboard': 'My Dashboard',
      'projects': 'Projects',
      'dashboard': 'Dashboard',
      'project-facts': 'Project facts',
      'dateneingabe': 'Data Input',
      'brandinghandbuch': 'Implementation Manual',
      'dokumente': 'Documents',
      'angebot': 'Offer',
      'montage': 'Assembly',
      'purchase': 'Order list',
      'picking-list': 'Picking list',
      'ladeliste': 'Loading list',
      'ruecklagerungsliste': 'Re-storage list',
      'tasks': 'Tasks',
      'informationspool': 'Information pool',
      'debriefing': 'Debriefing',
      'uebersicht': 'Settings',
      'admin': 'Administration',
      'archive': 'Archive',
      'chat': 'Chat',
      'position': 'Position',
      'vehicles': 'Vehicles',
      'vehicles-details': 'Vehicle details',
      'settings': 'Settings',
      'search': 'Search',
      'article': 'Articles',
      'article-details': 'Article details',
      'hotels': 'Hotels',
      'hotels-details': 'Hotel details',
      'user': 'Benutzer',
      'teams': 'Task teams',
      'teams-details': 'Teamdetails',
      'logistic': 'Logistic'
    },
    ride_complete: 'Complete',
    dueDate: 'Due Date',
    new_milestone: 'New Milestone',
    edit_milestone: 'Edit Milestone',
    debriefing: 'Debriefing',
    archive: 'Archive',
    daysRemaining: '{days} days to go',
    due: 'Due since {diff} days',
    dueAt: 'Due at:',
    tasksHeader: 'Tasks',
    tasksHeaderDone: '({done} of {taskAmount} done)',
    description: 'Description',
    new_password: 'New password',
    mounted: 'Mounted',
    not_mounted: 'Not mounted',
    fileName: 'File name',
    phone: 'Telephone',
    office: 'Office',
    noEvents: 'No notifications',
    noTasks: 'No Tasks',
    note: 'Note',
    content_release: 'Layout approval',
    einsatzorte: 'Locations',
    confirm_produktion: 'Should the position be set to "In production"?',
    confirm_action_pl: 'Is PL action required?',
    confirm_no_action_pl: 'Is PL action no longer required?',
    confirm_open: 'Should the position be set to "Open"?',
    confirm_bulk: 'Should all {count} positions be set to "{state}"?',
    already_released_warning: 'This position is already released!\nChanges are only allowed after consulting the production.',
    modify_released_confirmed: 'You can edit this position now.',
    confirm_ride_checked: 'Mark the ride as "complete.',
    confirm_ride_unchecked: 'Remove the status "complete".',
    show_checked_rides: 'Show completed rides',
    status_changed_success: 'Status successfully changed.',
    done: 'Done',
    total: 'Total:',
    totalPrice: 'Total price:',
    motive: 'Motive',
    id: 'ID',
    position: 'Position',
    personalnummer: 'Personnel Number',
    notificationAllSeen: 'All seen',
    notificationShowRead: 'Show read',
    height: 'Height',
    with_prices: 'Show prices',
    without_prices: 'Without prices',
    english: 'English',
    english_without_prices: 'English, no prices',
    all_sites_seperately: 'All pages separately',
    labels: 'Labels',
    show_logistic_status: 'Mark picked and loaded Positions',
    various: 'Various',
    footage: 'Images',
    result: 'Result',
    result_customer: 'Result customer',
    export_success: 'Pages were exported.',
    export_pdf_error: 'PDF could not be created: ',
    export_pages_error: 'Pages could not be exported: ',
    export_labels_error: 'Labels could not be exported: ',
    image: 'Image',
    changePosition: 'Layout',
    transportInsurance: 'Transport insurance',
    value: 'Value',
    PTNumberRange: 'PT number',
    PTOrdertext: 'Customer & project for PT ordertext',
    pt_update: 'PT update',
    Konfektion: 'Confection',
    printData: 'Print data',
    customerLogo: 'Customer-Logo',
    customerId: 'Cusomter ID',
    chooseCustomer: 'Choose customer',
    all_positions: 'All positions',
    select_position: 'Select position',
    all_areas: 'All areas',
    select_area: 'Select area',
    list_checked: 'List checked',
    list_unchecked: 'List unchecked',
    comment_unchecked: 'Comment not checked yet.',
    comment_checked_by_pl: 'Comment checked by PL.',
    komm_checked: 'Checked at ',
    komm_checked_by: ' by ',
    backNote: 'Checked / Note',
    createDynamicsOffer: 'Create Dynamics offer',
    offerNotification: 'All prices are displayed in EUR plus the legal value added tax.',
    positionBHB: 'Position IM',
    positionAreaBHB: 'Area & (Position) IM',
    construction: 'Construction',
    unit: 'Unit',
    width: 'Width (mm)',
    height: 'Height (mm)',
    read: 'Mark as read',
    all: 'All',
    customer: 'Customer',
    'eventDate-from': 'From',
    'eventDate-to': 'To',
    order: 'Order:',
    chooseOrder: 'Choose order',
    costUnit: 'Cost unit',
    responsiblePL: 'Responsible PM:',
    favorites: 'Favorites',
    noFavorites: 'No Favorites',
    contactPerson: 'Contact person',
    noEntries: 'No entries',
    export_start: 'The creation of the IM is in progress, please visit Documents in a few minutes.',
    commStates: {
      OFFEN: 'Open',
      BEMERKUNG: 'Comment',
      KOMMISSIONIERT: 'Picked'
    },
    confStates: {
      green: 'Done',
      yellow: 'In progress',
      'null': 'Open'
    },
    loadingStates: {
      OFFEN: 'Open',
      BEMERKUNG: 'Comment',
      GELADEN: 'Loaded'
    },
    mountedStates: {
      OFFEN: 'Open',
      MONTIERT: 'Mounted'
    },
    reStorageStates: {
      OFFEN: 'Open',
      BEMERKUNG: 'Comment',
      ZURUECK: 'Return'
    },
    uploadMessage: 'Place files here to upload',
    'delete-mat': 'Delete {title}',
    'reset-mat': 'Reset {title}',
    work: 'Work Report',
    ChooseTool: 'Choose Tool'
  },
  notifications: {
    clear: {
      title: 'Mark as read',
      message: 'Do you want to mark all notifications as read?'
    },
    image: {
      saved: 'Image successfully saved.'
    },
    notification: '{project} Notification',
    notification_desc: '{user_name} has done something interesting ({type}/{operation}).',
    comment: 'Comment',
    comment_desc: `You were mentioned by {listener_name} in a comment.`,
    comment_mentioned_desc: `{user_name} mentioned {listener_name} in a comment.`,
    comment_desc_project: `{user_name} wrote a comment.`,
    comment_comm: 'Comment on picking',
    comment_comm_desc: '{user_name} has commented the picking list.',
    comment_purchase: 'Comment on purchase list',
    comment_purchase_desc: '{user_name} has commented the purchase list.',
    comment_loading: 'Comment on loading list',
    comment_loading_desc: '{user_name} has commented the loading list.',
    comment_rueck: 'Comment on re-storage',
    comment_rueck_desc: '{user_name} has commented the re-storage list.',
    newsfeed_new: 'There is a new entry',
    bhb_state: 'New state of {key}: {note}',
    bhb_state_desc: '{user_name} has changed the status.',
    mounted: 'Assembly of {key}',
    mounted_desc: '{user_name} has the position marked as mounted.',
    rel_business: 'Financial approval from {key}',
    rel_business_desc: '{user_name} has financially approved this position.',
    task_new: 'New task from {user_name}',
    task_assigned: 'Task delegated by {user_name}',
    task_done: 'Task done by {user_name}',
    project_person_created: 'You were invited to a project by {user_name}.',
    project_person_created_project: 'A new team member has been added from {user_name}.',
    project_person_added_project: '{user_name} added {new_member_name} to the team'
  },
  milestone: {
    'veranstaltungszeitraum': 'Event dates',
    'deadline-druck': 'Deadline print data',
    'lade-termin': 'Loading date',
    state: 'Status'
  },
  internalPersonTopics: {
    'Senior-Projektleiter': 'Senior Projektleiter',
    'Projektleiter': 'Projektleiter',
    'Grafiker': 'Grafiker',
    'Techniker-Koordinator': 'Techniker-Koordinator',
    'Techniker': 'Techniker',
    'Bereichsleiter': 'Bereichsleiter',
    'Projektbetreuer': 'Projektbetreuer'
  },
  externalPersonTopics: {
    'Druckdaten': 'Druckdaten',
    'Gesamtkoordination': 'Gesamtkoordination',
    'Sicherheit': 'Sicherheit',
    'Hospitality': 'Hospitality',
    'Techniker': 'Techniker-Koordinator',
    'Medien': 'Medien',
    'Ticketing': 'Ticketing',
    'Stadionbetreiber': 'Stadionbetreiber'
  },
  tasks: {
    'show-done-tasks': 'Show done',
    'no-project': '(No Project)'
  },
  project: {
    functions: {
      D: 'Data Input',
      B: 'Implementation Manual',
      A: 'Offer',
      M: 'Assembly',
      E: 'Order',
      K: 'Picking list',
      L: 'Loading list',
      U: 'Re-storage list',
      I: 'Information pool',
      T: 'ToDos',
      R: 'Resource planning',
      C: 'Chat'
    },
    komm_orte: {
      s1: 'Schütte 1',
      s2: 'Schütte 2',
      s3: 'Schütte 3',
      s4: 'Schütte 4',
      s5: 'Schütte 5',
      s6: 'Schütte 6',
    },
    is_private: "Confidential Project",
    pt_sync_enabled: "Synchronise project with PTworkflow"
  },
  position: {
    'go-to-bhb': 'To IM page',
    im_position: "IM Position",
    category: "Categorie",
    unit: "Unit",
    width: "Width",
    height: "Height",
    pt_number: "PT Number",
    quantity: "Amount",
    construction: 'Construction',
    assembly: 'Assembly',
    material: 'Material',
    go_to_im: 'Go to IM page',
    price_unit: 'Price per unit',
    dialog: {
      delete: {
        title: 'Delete position',
        message: 'Do you really want to delete this item?'
      }
    }
  },
  comment: {
    action: {
      'position/state_bhb': 'Change of status',
      'position/state_bhb/ALLE': 'All status',
      'position/state_bhb/LEER': 'Empty',
      'position/state_bhb/OFFEN': 'Open',
      'position/state_bhb/KORREKTUR': 'Correction',
      'position/state_bhb/HANDLUNG_PL': 'Action PM',
      'position/state_bhb/FREIGEGEBEN': 'Approved',
      'position/state_bhb/IN_PRODUKTION': 'In Production',
      'position/state_bhb/ABGELEHNT': 'Rejected',
      'task/create': 'Task created',
      'project_person/create': 'Team member added',
      'comment/mention': 'Mentions',
      'comment/newsfeed': 'Newsfeed',
      'material/komm_bemerkung': 'Comment picking',
      'material/purchase_bemerkung': 'Comment order',
      'material/lade_bemerkung': 'Comment loading list',
      'material/rueck_bemerkung': 'Comment mounted',
      'material_additional/komm_bemerkung': 'Comment additional list',
      'position/mounted': 'mounted',
      'position/rel_business': 'Financial approval',
      'task/assign': 'Tasks assigned',
      'task/done': 'Tasks done'
    }
  }
}
